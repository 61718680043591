/*
From: https://gitlab.com/zapier/fb-embed-frontend/-/blob/staging/packages/service/src/hooks/use-logged-in.ts
*/
import { useCurrentAccountId } from "@zapier/identity";

import { V4SessionResponse } from "@/types/ZapierV4";
import {
  USE_OAUTH,
  V4_SESSION,
  isDevelopment,
  isPreview,
} from "@/utils/constants";

import useZapierSWR from "./useZapierSwr";

export type ZapierSession = {
  currentAccountId: number | null;
  customuserId: number | null;
  isLoading: boolean;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isStaff: boolean;
};

const useLoggedIn = (): ZapierSession => {
  let currentAccountId = useCurrentAccountId();
  const { data: session, error } = useZapierSWR<V4SessionResponse>(V4_SESSION);
  // When using OAuth, also fetch the session from the backend so we can make
  // sure it has the same session.
  const backendSwr = useZapierSWR<V4SessionResponse>(
    USE_OAUTH ? `/app/extensions/api/zapier/v4/session` : null,
  );

  // If we're not using OAuth, just use the frontend session to force a match.
  const backendSession = USE_OAUTH ? backendSwr?.data : session;
  const backendError = USE_OAUTH ? backendSwr?.error : error;

  if (!currentAccountId && (isPreview || USE_OAUTH)) {
    currentAccountId = session?.current_account_id;
  }

  // If someone switches between using the local monolith and OAuth, they can
  // have a leftover cookie that we need to ignore. So just use the one from
  // the session.
  if (USE_OAUTH && isDevelopment) {
    currentAccountId = session?.current_account_id;
  }

  // Confirms we have the same session on the frontend and backend.
  const isMatchingSsoHint =
    !USE_OAUTH || session?.sso_hint === backendSession?.sso_hint;

  const firstName = (isMatchingSsoHint && session?.first_name) || null;
  const lastName = (isMatchingSsoHint && session?.last_name) || null;
  const email = (isMatchingSsoHint && session?.email) || null;
  const isStaff = (isMatchingSsoHint && session?.is_staff) || false;

  return {
    currentAccountId:
      isMatchingSsoHint && session?.is_logged_in && currentAccountId
        ? currentAccountId
        : null,
    customuserId:
      isMatchingSsoHint && session?.is_logged_in && session?.id
        ? session?.id
        : null,
    isLoading: (!session && !error) || (!backendSession && !backendError),
    firstName,
    lastName,
    email,
    isStaff,
  };
};

export default useLoggedIn;
