"use client";

import { Menu } from "@headlessui/react";
import { BackButton, Icon, IconName } from "@zapier/design-system";
import { Avatar } from "@zapier/design-system/cjs/components/display/Avatar";
import { Spinner } from "@zapier/design-system/cjs/components/display/Spinner";
import { TagLabel } from "@zapier/design-system/cjs/components/display/TagLabel";
import { Text } from "@zapier/design-system/cjs/components/display/Text";
import { buildAccountSwitcherUrl } from "@zapier/identity";
import Link from "next/link";
import { notFound, usePathname } from "next/navigation";

import useAccounts from "@/hooks/useAccounts";
import useLoggedIn from "@/hooks/useLoggedIn";
import { ZAPIER_ORIGIN, isDevelopment, isPreview } from "@/utils/constants";

function Header({
  title,
  rootPath,
  rootTitle,
  tagLabel,
  documentationUrl,
}: {
  title?: string;
  rootPath?: string;
  rootTitle?: string;
  tagLabel?: string;
  documentationUrl?: string;
}) {
  const { accounts, isLoadingAccounts, accountsError } = useAccounts();
  const { currentAccountId } = useLoggedIn();
  const currentAccount = accounts?.find((account) => {
    return account.id === currentAccountId;
  });
  const pathname = usePathname();

  const menuLinks = [
    {
      href: "https://help.zapier.com/hc/en-us",
      text: "Support",
    },
    {
      href: `${ZAPIER_ORIGIN}/logout`,
      icon: "arrowExit" as IconName,
      text: "Log Out",
    },
  ];

  if (accountsError) {
    // Auth check is handled higher up (in LoginGuard)
    // so it's unlikely this error will be hit
    return notFound();
  }

  return (
    <header className="relative py-[10px] px-5 flex justify-between bg-neutral-800 items-center">
      <div className="flex items-center justify-between">
        {rootPath && pathname !== rootPath && (
          <BackButton
            isOnDarkBackground
            href={rootPath}
          />
        )}
        <div className="ml-3 mr-4 bg-brand relative rounded flex items-center justify-center w-11 h-11">
          <Icon
            name="navCustomAction"
            color="neutral100"
            size={32}
          />
        </div>
        {title && (
          <Text
            tag="h1"
            type="pageHeader9"
            color="neutral100"
            margin="0 10px 0px 0"
          >
            {title}
          </Text>
        )}
        {tagLabel ? <TagLabel size="small">{tagLabel}</TagLabel> : null}
      </div>
      {isLoadingAccounts && <Spinner size="medium" />}
      {accounts && currentAccount && (
        <div className="relative flex items-center gap-2">
          <div className="mr-10 flex items-center text-neutral-100 text-sm gap-4">
            {documentationUrl && (
              <a
                target="_blank"
                href={documentationUrl}
                className="hover:bg-neutral-700 cursor-pointer px-4 py-2 rounded-lg"
              >
                Documentation
              </a>
            )}
          </div>
          <div className="relative">
            <Menu>
              <Menu.Button className="relative">
                <Avatar
                  size={40}
                  name={currentAccount.name}
                  url={currentAccount.imageUrl}
                />
              </Menu.Button>
              <Menu.Items
                className="gap-1 bg-neutral-100 mt-1 right-0 p-5 border border-neutral-400 flex flex-col absolute rounded-lg shadow-lg z-10"
                data-testid="AccountsMenu"
              >
                {accounts.map((account) => (
                  <Menu.Item key={account.id}>
                    {({ active }) => (
                      <button
                        className={`${
                          active && "ring-2 ring-offset-2 ring-blue-500"
                        } ${
                          currentAccountId === account.id &&
                          "bg-blue-100 text-blue-600"
                        } p-3 w-[300px]  font-bold rounded-lg cursor-pointer text-left
                   gap-3 flex items-center `}
                        onClick={(e) => {
                          e.preventDefault();
                          const accountSwitchUrl = new URL(
                            `${ZAPIER_ORIGIN}${buildAccountSwitcherUrl(
                              account.id,
                            )}`,
                          );

                          if (
                            // We're on localhost
                            window.location.origin.includes("/localhost:") &&
                            // We're trying to switch using non-localhost
                            !(ZAPIER_ORIGIN || "").includes("/localhost:")
                          ) {
                            // Then switching will dump the user on the dashboard,
                            // so let them know to come back here.
                            window.alert(
                              `Switching accounts will take you to ${ZAPIER_ORIGIN}, ` +
                                `which cannot automatically return to ${window.location.origin}.\n\n` +
                                `After switching, use the back button to return to ${window.location.origin}. ` +
                                `You may also need to refresh the page when you return.`,
                            );
                          }

                          if (isPreview || isDevelopment) {
                            const next =
                              accountSwitchUrl.searchParams.get("next");
                            // For testing environments where Custom Actions has
                            // a different base URL than Zapier we need to insert it.
                            accountSwitchUrl.searchParams.set(
                              "next",
                              `${window.location.origin}${next}`,
                            );
                          }
                          const encodedParams = encodeURIComponent(
                            window.location.search,
                          );
                          window.location.href =
                            accountSwitchUrl.toString() + encodedParams;
                        }}
                      >
                        <Avatar
                          size={40}
                          name={account.name}
                          url={account.imageUrl}
                        />
                        <span>{account.name}</span>
                      </button>
                    )}
                  </Menu.Item>
                ))}

                {rootTitle && rootPath && (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={`p-3 font-medium w-[300px] text-neutral-700 rounded-lg cursor-pointer text-left ${
                          active && "bg-neutral-200 text-neutral-800"
                        }`}
                        href={rootPath}
                      >
                        {rootTitle}
                      </Link>
                    )}
                  </Menu.Item>
                )}
                <hr className="my-3 border-neutral-400" />
                {menuLinks.map((link) => (
                  <Menu.Item key={link.href}>
                    {({ active }) => (
                      <a
                        className={`p-3 flex text-neutral-700 items-center gap-4 w-[300px] rounded-lg cursor-pointer text-left ${
                          active && "bg-neutral-200 text-neutral-800"
                        }`}
                        href={link.href}
                        target="_blank"
                      >
                        {link.icon && (
                          <Icon
                            size={24}
                            color={active ? "neutral700" : "neutral600"}
                            name={link.icon}
                          />
                        )}
                        <span>{link.text}</span>
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
